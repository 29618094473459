@import url("https://use.typekit.net/igd6kon.css");
body {
  margin: 0;
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  background-color: white;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}