:root {
  --dark: #101010;
  --light: #F7F7FC;
  --cream: #EFF0F6;
  --gray: #A0A3BD;
  --orange: #F05827;
  --vanilla: #FDFAE0;
}

body {
  background-color: white;
}

.centered-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
}

.logo {
  width: 218px;
  margin-top: 100px;
}

/* h1 {
  font-weight: 400;
  color: var(--light);
  font-size: 32px;
  line-height: 42px;
  text-align: center;
  margin-top: 26px;
  max-width: 400px;
} */

.pill-row {
  margin-top: 32px;
  border-radius: 20px;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.pill-row .pill {
  flex: 1;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background-color: var(--vanilla);
  color: var(--dark);
  border: none;
  margin: 0;
  padding: 12px;
}

.pill-row .pill.selected {
  background-color: var(--orange);
  color: var(--light);
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

input,
textarea {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 24px;
  border-radius: 16px;
  margin-top: 36px;
  border: none;
  box-sizing: border-box;
  color: var(--dark);
}

input::placeholder,
textarea::placeholder {
  color: var(--gray);
}

textarea {
  height: 300px;
}

.submit-button {
  display: block;
  background-color: var(--orange);
  font-size: 16px;
  font-weight: 600;
  color: var(--cream);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 68px;
  border: none;
  border-radius: 40px;
  margin-top: 36px;
  align-self: center;
}

small {
  display: block;
  color: var(--orange);
  margin-top: 10px;
  margin-left: 10px;
}

.spinner-wrapper {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--dark);
}

.spinner-container {
  padding: 10rem 1rem 1rem 1rem;
  text-align: center;
}

.spinner {
  color: var(--cream);
  height: 50px;
  width: 50px;
  fill: var(--orange);
}

.animation-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes indicate {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 7px;
  background-color: var(--orange);
  animation: indicate 5s linear;
}